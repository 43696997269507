import logo from './assets/images/logo.png';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Your friendly Canadian Football shirt store!<br/>
                    Website coming soon!<br/>
                    In the meantime, check out my <a target={'_blank'} href={'https://www.instagram.com/dfootballhaus/'} rel={'noreferrer'}>Instagram</a>!
                </p>
            </header>
        </div>
    );
}

export default App;
